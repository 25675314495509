@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  font-style: normal;
  src: url("./font/Gilroy-Regular.woff2") format("woff2"),
         url("./font/Gilroy-Regular.woff") format("woff");
 }
 body{
   background-color: #E2E2E2 !important;
 }
 body, html { 
  width: 100%;
  height: 100%;
}
 @font-face {
  font-family: "GilroyBold";
  font-weight: 500;
  font-style: normal;
  src: url("./font/Gilroy-Bold.woff2") format("woff2"),
         url("./font/Gilroy-Bold.woff") format("woff");
 }
 .container {
   max-width: 1164px !important;
   padding: 0px !important;
 }
 .container .row{
   padding: 0px !important;
 }
 button{
  border: solid transparent;
 }
 *{
   font-family: "Gilroy";
 }
.App {
  text-align: center;

}
.App22{
  background-size: 100%;
  background-image: url(./img/Bg1.svg);
  background-position: top -708px right -5px;
  background-size: 573px 600px;
 
  
}
.App44{
  background-image: url(./img/bg.svg);
  background-position: top -556px right 0px;
  /* background-size: cover; */
  /* background-position: center; */
    background-repeat: no-repeat;
    /* background-size: cover; */
    height: 100vh;
}
.nav-link{
  color: black !important;
  font-weight: 900;
}
.padd_rgg {
  width: 182px;
  font-weight: 900;
  color: black !important;
}
.minhg{
  min-height: 733px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.disp_center{
  display: inline-block;
  position: relative;
  background-color: #131313;
  width: 100%;
  z-index: 50;
}
.padd_rgg{
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 0px !important;

}

.navbar-light .navbar-nav .nav-link {
  color: white !important
}
.footer{
  background-color: #A3A3A3;
  height: 120px;
  /* position: fixed; */
  font-size: 20px;
  font-weight: 900;
  width: 100%;
  bottom: 0px;
  z-index: 99999999;
}
.cs_cont_main_unkn{
  padding-top: 63px;
   
  color: white;
}
.bg_svet{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}
.au_main{
  /* position: relative;
  z-index: 50;
padding-top: 160px;
min-height: 979px; */

position: absolute;
left: 50%;
top: 50%;
-webkit-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
}
.absol_main{
  position: absolute;
  left: 0px;
  top: 0px;
}
.inl{
 margin-bottom: 30px;
}
.inl input::placeholder{
  font-weight: 900;
  font-size: 20px;
  color: #C2C2C2;
}
.inl input{
  background: #FFFFFF;
  border: 1px
solid #999999;
  box-sizing: border-box;
  font-family: GilroyBold;
  border-radius: 37px;
  Width: 475px;
  Height: 63px;
  padding-left: 40px;
font-size: 20px;
}
.inl input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
 
}
.inl input:focus-visible::placeholder {
  outline: -webkit-focus-ring-color auto 0px;
  color: transparent;
 
}
.reg_l{
  background: #8F1B3E;
  border-radius: 37px;
  width: 220px;
  height: 60px;
  display: inherit;
  font-family: GilroyBold;
  color: white;
  font-size: 20px;
  margin-right: 15px;
}
.reg_r{
  background: #000000;
  border-radius: 37px;
  width: 220px;
  font-family: GilroyBold;
  height: 60px;
  display: inherit;
  padding-top: 15px;
color: white;
font-size: 20px;
margin-left: 15px;

}
.reg_l:hover{
  color: white;
  text-decoration: none;
}
.reg_r:hover{
  color: white;
  text-decoration: none;
}
.rega_blo{
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
/* padding-top: 20px; */
}

.massrelav{
  position: relative;
  
}
.react-tel-input{
  position: relative;
  z-index: 55;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 20px !important;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 37px !important;
  line-height: 25px;
  height: 75px !important;
  width: 656px !important;
  outline: none;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f500 !important;
  border: 0px solid #cacaca !important;
  border-radius: 3px 0 0 3px;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: #61dafb00 !important;
}

.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  /* right: 0px; */
  left: 607px !important;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}
.mymask{
  z-index: 55;
    position: relative;
    width: 656px;
    height: 75px;
    border-radius: 37px;
    font-size: 24px;
    font-weight: 900;
    top:4px;
    padding-left: 115px;
    border: 1px solid #999999;
}
.mymask:focus{
  z-index: 55;
    position: relative;
    width: 656px;
    height: 75px;
    border-radius: 37px;
    border: 1px solid #999999;
}.mymask:focus-visible{
  z-index: 55;
    position: relative;
    outline: -webkit-focus-ring-color auto 0px;
    width: 656px;
    height: 75px;
    border-radius: 37px;
    border: 1px solid #999999;
}
.mymask::placeholder{
  color: #C2C2C2;
}
.container{
  z-index: 60;
  position: relative;
  text-align: left;

}
.countryPp{
  font-size: 48px;
  font-weight: 900;
  font-family: GilroyBold;
  margin-bottom: 48px;
}
.poloska{
  background-color: #8F1B3E;
  width: 100%;
  display: inline-block;
  height: 4px;
}
.relav{
  position: relative;
  /* text-align: center; */
  margin-top: 22px;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 345px;
  display: block;
}
.catname{
  float: left;
  position: relative;
  font-size: 20px;
  /* padding: 20px; */
  padding-top: 20px;
  padding-bottom: 13px;
  padding-left: 12.9px;
  padding-right: 12.9px;
  bottom: -17px;
  color: black;
  font-family: GilroyBold;

  background-color: transparent;

}
.catname1{
  float: left;
  position: relative;
  font-size: 20px;

  font-family: GilroyBold;
  bottom: -17px;
  background-color: #8F1B3E;
  color: white;
  border-radius: 15px 15px 0px 0px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 12.9px;
  padding-right: 12.9px;
}
.catname:focus{
  float: left;
  position: relative;
  font-size: 20px;

  font-family: GilroyBold;
  bottom: -17px;
  background-color: #8F1B3E;
  color: white;
  border-radius: 15px 15px 0px 0px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 12.9px;
  padding-right: 12.9px;
}
.operat{
  color: black;
  font-size: 38px;
  margin: 0px;
  font-family: GilroyBold;
}
.poloska2{
  background-color: #999999;
  display: inline-block;
  width: 100%;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.lefttext{
 
  float: left;
    padding: 40px;
    padding-left: 0px !important;
    /* background-color: #5D0922; */
    /* font-weight: bold; */
    font-weight: 600;
    color: #333333;
    font-size: 24px;
    padding-right: 26px;
}
.lefttext0{
  float: left;
  padding: 40px;
  font-size: 24px;
  font-weight: bold;
  padding-right: 142px;
  background-color: #5D0922;
  color: white;
  /* border: 2px solid #8F1B3E; */
  border: 2px solid #8F1B3E;
  box-sizing: border-box;
  border-radius: 23px;
}
.disl{
  display: inline-block;
  width: 100%;
  margin-top: 20px;

}
.dina{
font-size: 55px;
font-weight: 900;
margin: 0px;

position: relative;
top: -12px;
height: 54px
;
}
.curen{
  font-weight: bold;
  height: 40px;
  margin: 0px;
  background-color: #141414;
  position: relative;
  padding-top: 4px;
  bottom: -10px;
  left: -6px;
  font-size: 20px;
  width: 160px;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}
.reci{
font-size: 23px;
font-weight: 900;
margin: 0px;

}
.buys{
  float: left;
  /* width: 180/px; */
  height: 154px;
  margin-right: 23px;
  font-weight: bold;
  float: left;
  width: 166px;
  height: 154px;
  margin-right: 23px;
  background: #8F1B3E;
  border: 3px solid #BDBDBD;
  box-sizing: border-box;
  box-shadow: 0px 6px 0px rgb(0 0 0 / 40%);
  border-radius: 20px;
  text-align: center;
  color: white;
  margin-bottom: 23px;
}

/* .buyactive:hover{
  float: left;

  height: 154px;
  margin-right: 23px;
  font-weight: bold;
  float: left;
  width: 166;
  height: 154px;
  margin-right: 23px;
  background: #8F1B3E;
  border: 3px solid #BDBDBD;
  box-sizing: border-box;
  box-shadow: 0px 6px 0px rgb(0 0 0 / 40%);
  border-radius: 20px;
  text-align: center;
  color: white;
  margin-bottom: 23px;
  text-decoration: none;
} */
.buyactive{
  float: left;
  width: 166px;
  height: 154px;
  margin-right: 23px;
  font-weight: bold;
  float: left;
 
  height: 154px;
  margin-right: 23px;
  background: red;
  background-color: white;
  border: 3px solid #141414;
  box-sizing: border-box;
  box-shadow: 0px 6px 0px rgb(0 0 0 / 00%);
  border-radius: 20px;
  text-align: center;
 
  margin-bottom: 23px;
  text-decoration: none;
}
.diveresnones{
  display: inline-block;
  width: 100%;
}
.buyactive .dina {
  color: #8e1b3f;
}
.buyactive  .reci{
  color: #8e1b3f;
}
.recoress{
  float: left;
    width: 166px;
    /* height: 140px; */
    display: table-cell;
    margin-right: 28px;
}
.cont_recivers{
  display: table;
}
.bg_bus{
  background-color: #E2E2E2;

  width: 100%;
  z-index: 47;
  position: relative;
  /* margin-top: 2p; */
  margin-top: 40px;
  min-height: 466px;
}

.pay{
  background: #8F1B3E;
  border-radius: 37px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  width: 264px;
  height: 75px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 48px;
}
.pay2{
  background: #a3a3a3;
  border-radius: 37px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  width: 264px;
  height: 75px;
  text-align: center;

  margin-top: 30px;
  margin-bottom: 48px;
}
.preloaders{
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 99999;
}
.trace__Ball-k17r81-1{

  border: 2px solid #8F1B3E  !important;
}
.sc-bdVaJa {
  border:  solid #8F1B3E !important;
}
.groupmodal{

}
.textleft{
float: left;
font-size: 24px;
padding-top: 25px;
}
.textright{
float: right;
background: #FFFFFF;
border: 1px solid #999999;
box-sizing: border-box;
border-radius: 37px;
padding-left: 40px;
width: 558px;
height: 75px;
}
.maingl{
  margin-bottom: 30px;
}
.titlemain{
  font-size: 48px;
  font-weight: bold;
}
.linebot{
  width: 100%;
  height: 1px;
  display: inline-block;
  background-color: #999999;
}
.save{
  background: #8F1B3E;
border-radius: 37px;
width: 264px;
height: 75px;
color: white;margin-bottom: 20px;
position: relative;
left: 363px;

}
.react-tel-input .country-list{
display: none;
}
.navbar-brand{
  margin-right: 94px !important;
}
.profile_head{
  margin-right: 86px;
}
.active{
  background-color: #8F1B3E;

  background-color: #8F1B3E;
  position: relative;
  top: -35px;
    height: 105px;
    font-size: 14px;
    color: white !important;
    border-radius: 0px 0px 15px 15px;
    padding-top: 8px;
}


.hd_none{
  background-color:transparent !important;

  background-color: transparent !important;
  position: relative;
  top: 0px;
    height: 0px;
    font-size: 17px;
    color: black !important;
    border-radius: 0px 0px 0px 0px;
    padding-top: 0px !important; 
}
.actDiv{
  position: relative;
  width: 100%;
}

.justify-content-center{
  height: 120px;
}
.botomheadtextbtn{
  position: relative;
  bottom: -9px;
  color: white;
  font-size: 20px;
  float: left;
  width: 100%;
}
.botomheadtextbtn:hover{
  position: relative;
  text-decoration: none;
  bottom: -9px;
  color: white;
  font-size: 20px;
}
.flleft{
  float: left;
  padding-right: 5px;
}
.navbar-nav{
  height: 50px;
}
.row_main{
  margin-top: 40px;
  margin-bottom: 40px;
}
.gli{
  font-size: 20px;
  margin-bottom: 40px;
  font-weight: 900;
}
.catname{
  font-weight: 900;
}
.welcomess{
  font-weight: 900;
  margin-top: 16px;
  font-size: 48px;
  font-family: GilroyBold;
  margin-bottom: 0px;
}
.contin{
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 900;
}
.forgot{
  display: initial;
  font-size: 20px;
  font-weight: 900;
  /* text-decoration: underline solid #8F1B3E !important; */
  color: black;
  text-decoration: #8F1B3E;
  margin-top: 30px !important;
  /* padding-bottom: 237px; */
  padding-bottom: 5px;
  border-bottom: 4px solid #8F1B3E;
  margin-top: 30px;
}
.forgot:hover{
  display: initial;
  font-size: 20px;
  font-weight: 900;
  /* text-decoration: underline solid #8F1B3E !important; */
  color: black;
  text-decoration: #8F1B3E;
  margin-top: 30px !important;
  /* padding-bottom: 237px; */
  padding-bottom: 5px;
  margin-top: 30px;
  border-bottom: 4px solid #8F1B3E;
}
.dropers{
  position: relative;
}
.lisssssss{
  
}
.subliste{
  border: 1px solid #CCCCCC;
  position: absolute;
  width: 20px;
  border-left: 0px !important;
  height: 401px;
  /* right: 0px; */
  left: 636px;
  top: 89px;
  /* border-right: 28px white solid; */
  overflow: auto;
  border-top: 0px;
  background-color: white;
  border-bottom: 0px !important;
}
.listsete{
  border: 1px solid #CCCCCC;
  position: absolute;
  width: 640px;
  max-height: 400px;
  top: 90px;
  /* border-right: 28px white solid; */
  overflow: auto;
  border-top: 0px;
  z-index: 5555555;
  background-color: white;
  border-right: 0px;
border-bottom: 20px solid white;
}
.imgflagews{
  width: 56px;
  /* border-radius: 50%; */
  height: 56px;
  margin-left: 11px
;
  margin-right: 30px;
  margin-bottom: 15px;
}
.achrones{
  background-color: white;
  display: inline-block;
  width: 100%;
  float: left;
  text-align: left;
}
.achrones:hover{
  text-decoration: none;
}
.listsete1{
  position: absolute;
  width: 656px;
  border-top-left-radius: 19px;
  border-top-right-radius: 19px
  ;
  height: 65px;
  max-height: 400px;
  /* overflow: auto; */
  top: 25px;
  display: block;
  border: 1px solid #CCCCCC;
  border-bottom: 0px;
  z-index: 3;
  background-color: white;
}






.listsete::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.listsete::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.listsete::-webkit-scrollbar-thumb {
  background: #8F1B3E; 
  border-radius: 10px;
}

/* Handle on hover */
.listsete::-webkit-scrollbar-thumb:hover {
  background: #8F1B3E; 
}
.nameconet{
font-size: 24px;
font-weight: 900;color: black;
}
.prefixes{
font-size: 24px;
font-weight: 900;
color: #8F1B3E;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.falgase{
  width: 56px;
  height: 56px;
  /* background-color: red; */
  position: absolute;
  left: 20px;
  top: 13px;
  z-index: 64;
  /* border-radius: 50%; */
}
.improducer{
  border-radius: 20px;
  width: 166px;
  height: 140px;
  padding: 20px;
  border: 3px solid #BDBDBD;
box-sizing: border-box;
box-shadow: 0px 6px 0px rgba(0, 0, 0, 0.4);
border-radius: 20px;
background: white;
}
.busettings{
  font-size: 20px;
}
.nav-link{
  margin-right: 29px
  ;
}
.poloska10{
  margin-bottom: 10px;
  margin-top: 10px;
}
.canfloor222{
  padding: 0px !important;
}



.nanacuber{
  /* height: 150px; */
  display: inline-block;
  /* padding-top: 50%; */
  padding-top: 46px;
  text-align: center;
}

.imglondos{
  max-height: 250px;
  margin-top: 160px;
}

.btnsave{
 
  background-color: transparent !important;
  margin-left: 30px;
  padding: 0px;
}
.btnclear{
  background-color: transparent !important;
  margin-left: 30px;
  padding: 0px;
}






.upclasters{
  transform: rotate(
180deg);
}
 
.ffff1331{
  font-weight: 900;
}
.ffff11{
  font-weight: 900;
  background-color: black;
}
.btntestrw{
  width: 75px;
  height: 75px;
  background-color: black;
}
.centeralignsye {
  display: inline-block;
  max-width: 100%;
  text-align: center;
  border-bottom: 4px
solid #8F1B3E;
  padding-bottom: 7px
;
  margin-bottom: 50px
;
}
.rerow{
  display: inline-block;
}
.nuless{
  position: absolute;
}
.mycoonbnnl{
  padding-left: 67px !important;
}
.lenesert{
  left: 15px;
  overflow: hidden;
}
.lenesert button{

}
.redes{
color: #8F1B3E;
 font-weight: 900;

font-size: 20px;
position: absolute;
bottom: -68px;
}
.wrongersp{
  margin-bottom: 30px;
  font-family: GilroyBold;
}
.wareappers{
  display: inline-block;
  width: 100%;
  text-align: center;
  
}
.maintiperese{
  width: 517px;
  background-color: white;
  margin-top: 50px;
  display: inline-block;
  border-radius: 13px;
  margin-bottom: 50px;
  border: 3px solid #02752F;

}
.topbarch{
  font-family: GilroyBold;

  font-size: 20px;
  color: white;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  width: 100%;
  background-color: #02752F;
  display: inline-block;
  /* height: 47px; */
  padding: 13px;
}
.taleft{
float: left;
font-family: GilroyBold;
font-size: 20px;
}
.taright{
  float: right;

font-size: 20px;
}
.tabmainn{
  display: inline-block;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 18px;
}
.gruppadbtn{


  padding-bottom: 30px;
  display: inline-block;
  width: 100% ;
}
.cash{
  margin-left: 22px;
  margin-right: 22px;
color: white;
background-color: #8F1B3E;
/* margin-right: 5px; */
/* font-size: 20px; */
width: 75px;
height: 75px;
padding: 0px;
border-radius: 200px;
}
.cash img{
  width: 30px;
    height: 30px;
}
.print{
  color: white;
  background-color: #8F1B3E;
  margin-right: 5px;
  border-radius: 5px;
  font-size: 20px;
}
.rep{
  color: white;
  background-color: #8F1B3E;
  margin-right: 5px;
  border-radius: 5px;
  font-size: 20px;
}


.homeepaggg{
  margin-left: 22px;
  margin-right: 22px;
  color: white;
  background-color: black;
  /* margin-right: 5px; */
  /* font-size: 20px; */
  width: 75px;
  height: 75px;
  padding: 0px;
  border-radius: 200px;
  }
  .homeepaggg img{
    width: 30px;
    height: 30px;
  }
.tableplashk{
  /* padding-top: 15px; */
  /* padding-bottom: 15px; */
}
.linenewr12{
width: 100%;
height: 2px;
background-color: #E2E2E2;
}
.btnsubs{
  display: none;
}
.btnproductenter{
  background-color: transparent;
}

.productslist{
  padding: 6px !important;
  float: left;
}
.consoles{
  display: inline-block;
  width: 100%;
  position: relative;
  left: 3px;
}
.btnmycenter{
  padding: 0px;
  width: 166px;
  float: left;
  margin-right: 23px;
  margin-bottom: 23px;

}
.textprodec{
  margin: 0px;
  margin-top: 20px;
  font-size: 40px;
 font-weight: 900;
 margin-left: 10px;
 font-family: GilroyBold;
 color: black;
}
.imoprs{
  padding: 0px !important;
}
.opersurl{
  /* width: 100%;
  max-width: 549px; */
  max-height: 115px;
}
.deactivebtncenter img{
  background-color: #cbc9c9;
}
.borderbts{
  display: inline-block;
  
}
.prodol{
  width: 100%;
  position: relative;
  left: -30px;
}
.lineopt{
  width: 97%;
  display: inline-block;
  height: 4px;
  background-color: #8F1B3E;
 
}
.lineopt2222{
  width: 97%;
  display: inline-block;
  height: 4px;
  background-color: transparent; 
}
.classkureperes{
  padding-left: 0px;
}


.bbbttline{
  display: inline-block;
}


/* deactivebtncenter */